import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 处理页面跳转后不在顶部问题
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

import { detectZoom } from '../utils/detectZoom.js';
const m = detectZoom();
document.body.style.zoom = 100 / Number(m);
// console.log('m', Number(m))
// 把Number(m)全替换为了200 （为了兼容大屏显示）\(^o^)/~
// document.body.style.zoom = 100 / 200
//判断屏幕是否为4k
if (window.devicePixelRatio == 2) {
  document.body.style.zoom = 160 / Number(m);
} else {
  document.body.style.zoom = 100 / Number(m);
}
// if (window.screen.width * window.devicePixelRatio >= 3840) {
//   switch (m) {
//     // 4k屏时屏幕缩放比为100%
//     case 100:
//       document.body.style.zoom = 100 / 50;
//       break;
//       // 4k屏时屏幕缩放比为125%
//     case 125:
//       document.body.style.zoom = 100 / 62.5;
//       break;
//       // 4k屏时屏幕缩放比为150%
//     case 150:
//       document.body.style.zoom = 100 / 75;
//       break;
//       // 4k屏时屏幕缩放比为175%
//     case 175:
//       document.body.style.zoom = 100 / 87.4715;
//       break;
//       // 4k屏时屏幕缩放比为200%
//     case 200:
//       document.body.style.zoom = 100 / 100;
//       break;
//       // 4k屏时屏幕缩放比为225%
//     case 225:
//       document.body.style.zoom = 100 / 112.485;
//       break;

//     default:
//       break;
//   }
//   document.body.style.zoom = 100 / (Number(m) / 2);
//   document.body.style.zoom = 100 / 100;
// } else {
//   document.body.style.zoom = 150 / Number(m);
//   document.body.style.zoom = 145 / 200;
// }


Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
