<template>
  <div>
    <div class="block">
      <el-carousel height="700px">
        <el-carousel-item v-for="item in carousel" :key="item.id">
          <a :href="item.path">
            <img :src="item.img_url" alt="img" style="width: 100%; height: auto;">
          </a>
          <!-- <img :src="item.img_url" alt="img" style="width: 100%; height: 100%;"> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-row>
      <el-col :span="24">
        <div class="resize_img">
          <div class="info">
            <h1 style="font-weight: bold; font-family: Micro TechnicExt-Bold; font-size: 45px;">XR-3</h1>
            <p style="margin-top: -5px;"><span>2 K · 3 摄</span><span style="margin-left: 25px;">旗 舰 级 记 录 仪</span></p>
            <p style="margin-top: 15px;"><a href="#" style="color: #0088FE; font-size: 13px;">了解更多信息 ></a></p>
          </div>
        </div>
        <div class="resize_img two_img" style="margin-top: 7px">
          <div class="info" style="top: 29%;left: 25%;">
            <h1 style="font-weight: bold; font-family: Micro TechnicExt-Bold; font-size: 45px;">G3</h1>
            <p style="margin-top: -5px;"><span>2 K · 3 摄</span><span style="margin-left: 25px;">摩 托 车 胎 压 监 测</span></p>
            <p style="margin-top: 15px;"><a @click="reload" href="#home/g3" style="color: #0088FE; font-size: 13px;">了解更多信息 ></a></p>
          </div>
        </div>
        <div class="content_btm">
          <div class="content_item">
            <div class="item_info">
              <p style="font-weight: 500; font-size: 35px;">
                <el-link :underline="false" @click="reload" href="#home/aio5">车机产品</el-link>
              </p>
              <p style="margin-top: 5px;"><a @click="reload" href="#home/aio5" style="color: #0088FE;">了解更多信息 ></a></p>
            </div>
          </div>
          <div class="content_item">
            <div class="item_info">
              <p style="font-weight: 500; font-size: 35px;">
                <el-link :underline="false" @click="reload" href="#home/cg2">摩托手机支架</el-link>
              </p>
              <p style="margin-top: 5px;"><a @click="reload" href="#home/cg2" style="color: #0088FE;">了解更多信息 ></a></p>
            </div>
          </div>
          <div class="content_item">
            <div class="item_info">
              <p style="font-weight: 500; font-size: 35px;">
                <el-link :underline="false" @click="reload" href="#home/aio5">行车记录仪</el-link>
              </p>
              <p style="margin-top: 5px;"><a @click="reload" href="#home/aio5" style="color: #0088FE;">了解更多信息 ></a></p>
            </div>
          </div>
          <div class="content_item">
            <div class="item_info" style="top: 32%; left: 76%;">
              <p style="font-weight: 500; font-size: 35px;">
                <el-link :underline="false" @click="reload" href="#/home/parts">配件</el-link>
              </p>
              <p style="margin-top: 5px;"><a @click="reload" href="#/home/parts" style="color: #0088FE;">了解更多信息 ></a></p>
            </div>
          </div>
        </div>
        <div class="main_btm"></div>
      </el-col>
    </el-row>
  </div>

</template>
<script>
export default {
  data() {
    return {
      XR: require('../../../public/images/aio/XR-3.png'),
      G3: require('../../../public/images/aio/G3.png'),
      // 轮播图
      carousel: [
        {
          id: 1,
          img_url: require('../../../public/images/aio/lunbo1.png'),
          path: '#/home/aio5'
        },
        {
          id: 2,
          img_url: require('../../../public/images/aio/lunbo2.png'),
          path: '#/home/aio5'
        }
      ]
    }
  },
  methods: {
    reload() {
      setTimeout(() => {
        location.reload()
      })
    }
  }
}
</script>
<style scoped lang="less">
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.resize_img {
  position: relative;
  width: 100%;
  height: 600px;
  background: url('../../../public/images/aio/XR-3.png');
  background-repeat: no-repeat;
  // background-size: auto 100%;
  background-size: cover;
}
.info {
  position: absolute;
  top: 15%;
  left: 58%;
  width: 260px;
  height: 120px;
  // background: pink;
  color: #111;
  text-align: left;
}
.two_img {
  background-image: url('../../../public/images/aio/G3.png');
}
.content_btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .content_item {
    position: relative;
    margin-top: 7px;
    width: 49.65%;
    height: 460px;
    background-repeat: no-repeat;
    // background-size: auto 100%;
    background-size: cover;
    .item_info {
      position: absolute;
      top: 9%;
      left: 50%;
      transform: translateX((-50%));
      width: 250px;
      height: 100px;
      // background-color: pink;
    }
  }
  .content_item:nth-child(1) {
    background-image: url('../../../public/images/aio/cheji.png');
  }
  .content_item:nth-child(2) {
    background-image: url('../../../public/images/aio/motuo.png');
  }
  .content_item:nth-child(3) {
    background-image: url('../../../public/images/aio/xingche.png');
  }
  .content_item:nth-child(4) {
    background-image: url('../../../public/images/aio/peijian.png');
  }
}
.main_btm {
  // width: 100%;
  height: 230px;
  background-color: #797979;
  margin-top: 7px;
}
</style>