import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home/aio' },
  {
    path: '/test',
    component: () => import('../views/Test.vue')
  },
  {
    path: '/collection',
    component: () => import('../components/home/Collection.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'aio_product',
        component: () => import('../components/home/AioProduct.vue')
      },
      {
        path: 'todealer',
        meta: {
          title: '成为经销商'
        },
        component: () => import('../components/home/Todealer.vue')
      },
      {
        path: 'aio',
        meta: {
          title: '骑技科技'
        },
        component: () => import('../components/home/AIO.vue')
      },
      {
        path: 'aio5',
        meta: {
          title: 'AIO-5'
        },
        component: () => import('../components/home/Aio5.vue'),
        children: [
          {
            path: 'one',
            component: () => import('../components/home/aio5/Ofenone.vue'),
          },
          {
            path: 'two',
            component: () => import('../components/home/aio5/Ofentwo.vue'),
          },
          {
            path: 'three',
            component: () => import('../components/home/aio5/Ofenthree.vue'),
          },
          {
            path: 'four',
            component: () => import('../components/home/aio5/Ofenfour.vue'),
          },
          {
            path: 'five',
            component: () => import('../components/home/aio5/Ofenfive.vue'),
          },
          {
            path: 'six',
            component: () => import('../components/home/aio5/Ofensix.vue'),
          },
          {
            path: 'seven',
            component: () => import('../components/home/aio5/Ofenseven.vue'),
          },
          {
            path: 'eight',
            component: () => import('../components/home/aio5/Ofeneight.vue'),
          },

        ]
      },
      {
        path: 'g3',
        meta: {
          title: 'G3'
        },
        component: () => import('../components/home/G3.vue'),
        children: [
          {
            path: 'one',
            component: () => import('../components/home/aio5/Ofenone.vue'),
          },
          {
            path: 'two',
            component: () => import('../components/home/aio5/Ofentwo.vue'),
          },
          {
            path: 'three',
            component: () => import('../components/home/aio5/Ofenthree.vue'),
          },
          {
            path: 'four',
            component: () => import('../components/home/aio5/Ofenfour.vue'),
          },
          {
            path: 'five',
            component: () => import('../components/home/aio5/Ofenfive.vue'),
          },
          {
            path: 'six',
            component: () => import('../components/home/aio5/Ofensix.vue'),
          },
          {
            path: 'seven',
            component: () => import('../components/home/aio5/Ofenseven.vue'),
          },
          {
            path: 'eight',
            component: () => import('../components/home/aio5/Ofeneight.vue'),
          },

        ]
      },
      {
        path: 'cg2',
        meta: {
          title: 'CG-2'
        },
        component: () => import('../components/home/Cg2.vue'),
        children: [
          {
            path: 'one',
            component: () => import('../components/home/aio5/Ofenone.vue'),
          },
          {
            path: 'two',
            component: () => import('../components/home/aio5/Ofentwo.vue'),
          },
          {
            path: 'three',
            component: () => import('../components/home/aio5/Ofenthree.vue'),
          },
          {
            path: 'four',
            component: () => import('../components/home/aio5/Ofenfour.vue'),
          },
          {
            path: 'five',
            component: () => import('../components/home/aio5/Ofenfive.vue'),
          },
          {
            path: 'six',
            component: () => import('../components/home/aio5/Ofensix.vue'),
          },
          {
            path: 'seven',
            component: () => import('../components/home/aio5/Ofenseven.vue'),
          },
          {
            path: 'eight',
            component: () => import('../components/home/aio5/Ofeneight.vue'),
          },

        ]
      },
      {
        path: 'parts',
        meta: {
          title: '配件'
        },
        component: () => import('../components/home/Parts.vue')
      },
      {
        path: 'contact',
        meta: {
          title: '联系我们'
        },
        component: () => import('../components/home/Contact.vue')
      },
      {
        path: 'support',
        meta: {
          title: '支持'
        },
        component: () => import('../components/home/Support.vue'),
        children: [
          {
            path: 'one',
            component: () => import('../components/home/aio5/Ofenone.vue'),
          },
          {
            path: 'two',
            component: () => import('../components/home/aio5/Ofentwo.vue'),
          },
          {
            path: 'three',
            component: () => import('../components/home/aio5/Ofenthree.vue'),
          },
          {
            path: 'four',
            component: () => import('../components/home/aio5/Ofenfour.vue'),
          },
          {
            path: 'five',
            component: () => import('../components/home/aio5/Ofenfive.vue'),
          },
          {
            path: 'six',
            component: () => import('../components/home/aio5/Ofensix.vue'),
          },
          {
            path: 'seven',
            component: () => import('../components/home/aio5/Ofenseven.vue'),
          },
          {
            path: 'eight',
            component: () => import('../components/home/aio5/Ofeneight.vue'),
          },

        ]
      },
      {
        path: 'support_video',
        redirect: 'support_video/video_one',
        meta: {
          title: '教程视频'
        },
        component: () => import('../components/home/support/SupportVideo.vue'),
        children: [
          {
            path: 'video_one',
            component: () => import('../components/home/support/video/VideoOne.vue')
          },
          {
            path: 'video_two',
            component: () => import('../components/home/support/video/VideoTwo.vue')
          },
          {
            path: 'video_three',
            component: () => import('../components/home/support/video/VideoThree.vue')
          },
          {
            path: 'video_four',
            component: () => import('../components/home/support/video/VideoFour.vue')
          },
          {
            path: 'video_five',
            component: () => import('../components/home/support/video/VideoFive.vue')
          },
        ]
      },
      {
        path: 'support_download',
        meta: {
          title: '下载'
        },
        component: () => import('../components/home/support/DownLoad.vue'),
        children: [
          {
            path: 'down_one',
            meta: {
              title: '下载'
            },
            component: () => import('../components/home/support/download/DownOne.vue')
          }
        ]
      },
      {
        path: 'ofenanswer',
        meta: {
          title: '常见问题'
        },
        component: () => import('../components/home/Ofenblem.vue'),
        children: [
          {
            path: 'ofen_one',
            component: () => import('../components/home/ofenblem/Ofenone.vue')
          },
          {
            path: 'ofen_two',
            component: () => import('../components/home/ofenblem/Ofentwo.vue')
          },
          {
            path: 'ofen_three',
            component: () => import('../components/home/ofenblem/Ofenthree.vue')
          },
          {
            path: 'ofen_four',
            component: () => import('../components/home/ofenblem/Ofenfour.vue')
          },
          {
            path: 'ofen_five',
            component: () => import('../components/home/ofenblem/Ofenfive.vue')
          },
          {
            path: 'ofen_six',
            component: () => import('../components/home/ofenblem/Ofensix.vue')
          },
          {
            path: 'ofen_seven',
            component: () => import('../components/home/ofenblem/Ofenseven.vue')
          },
          {
            path: 'ofen_eight',
            component: () => import('../components/home/ofenblem/Ofeneight.vue')
          }
        ]
      },
    ]
  },
  {
    path: '/aio_duct',
    component: () => import('../views/Aioduct.vue')
  },
  {
    path: '/g3',
    meta: {
      title: 'G3'
    },
    component: () => import('../views/G3.vue')
  },
  {
    path: '/cg2',
    meta: {
      title: 'CG2'
    },
    component: () => import('../views/CG2.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
