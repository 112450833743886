<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark">
          <div class="nav">
            <router-link to="/home/aio">
              <img :src="head_logo" alt="logo" class="img_resize" style="margin-top: -12px; margin-right: 30px;">
            </router-link>
            <div class="nav_menu">
              <router-link to="" @click.native.prevent="showGoods">车机产品</router-link>
              <router-link to="" @click.native.prevent="showGoods2">手机支架</router-link>
              <router-link to="" @click.native.prevent="showGoods3">行车记录</router-link>
              <router-link to="" @click.native.prevent="showGoods4">胎压监测</router-link>
              <router-link to="/home/support">支持</router-link>
            </div>
            <el-button type="primary" size="mini" style="margin-left: 200px; transform: translateY(-4px);">商城</el-button>
            <span class="el-icon-search search" @mouseover="searchMove"></span>
            <el-input v-model="input" placeholder="请输入内容" class="ipt" @mouseleave.native="iptLeave"></el-input>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>
<script>
export default {
  data() {
    return {
      head_logo: require('../../public/images/head_logo.png'),
      input: '' // 搜索框
    }
  },
  methods: {
    // 鼠标经过放大镜图标
    searchMove() {
      let ele = document.querySelector('.search')
      ele.style.display = 'none'
      let iptEle = document.querySelector('.ipt')
      iptEle.style.display = 'inline-block'
    },
    // 鼠标从输入框离开
    iptLeave() {
      let ele = document.querySelector('.search')
      let iptEle = document.querySelector('.ipt')
      ele.style.display = 'inline-block'
      iptEle.style.display = 'none'
    },
    // 子组件通知父组件显示商品按钮
    showGoods() {
      this.$emit('clickGoods')
    },
    showGoods2() {
      this.$emit('clickGoods2')
    },
    showGoods3() {
      this.$emit('clickGoods3')
    },
    showGoods4() {
      this.$emit('clickGoods4')
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  position: absolute;
  border-radius: 4px;
}
.bg-purple-dark {
  background: #101010;
}
.grid-content {
  position: relative;
  border-radius: 4px;
  height: 60px;
  line-height: 60px;
  text-align: left;
  .nav {
    width: 60%;
    position: absolute;
    left: 55%;
    transform: translateX((-50%));
    height: 60px;
    line-height: 60px;
    .search {
      color: #fff;
      font-size: 20px;
      margin-left: 15px;
    }
    .ipt {
      display: none;
      width: 200px;
      margin-left: 15px;
      animation: reWidth 1s;
    }
    @keyframes reWidth {
      0% {
        width: 0;
      }
      100% {
        width: 200px;
      }
    }
    .nav_menu {
      height: 100%;
      box-sizing: border-box;
      display: inline-block;
      padding-top: 5px;
      a {
        font-size: 16px;
      }
    }
    a {
      color: #fff;
      font-size: 18px;
      margin-left: 20px;
    }
  }
}
.img_resize {
  width: auto;
  height: auto;
  max-width: 160px;
  vertical-align: middle;
}
</style>>

 
