<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import resizeMixin from '../config/resizeMixin'
export default {
  // mixins: [resizeMixin],
  name: 'App',
  data() {
    return {}
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // width: 100vw;
  // height: 100vh;
  // margin: 0 auto;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
// body {
//   max-width: 1920px;
//   min-width: 900px;
//   width: 1920px;
// }
</style>
