<template>
  <el-container>
    <el-header>

      <Head @clickGoods="showgoods" @clickGoods2="showgoods2" @clickGoods3="showgoods3" @clickGoods4="showgoods4"></Head>
    </el-header>
    <el-main>
      <div id="option1" class="optionGoods" @mouseleave="hiddenGoods">
        <router-link to="/home/aio5">
          <div style="float: left; width: 50px; height: 50px; margin-top: 25px;">
            <div class="nav_img_one" style="width: 50px; height: 50px; margin-bottom: -35px;"></div>
            <span style="font-size: 12px; color: #111111;">AIO-5</span>
          </div>
        </router-link>
        <router-link to="/home/parts">
          <div style="background-size: 100% 100%; float: left; width: 50px; height: 50px; margin-top: 25px; margin-left: 80px;">
            <div class="nav_img_two" style="background-size: 100% 100%; width: 40px; height: 40px; margin-bottom: -25px;"></div>
            <span style="font-size: 12px; color: #111111;">配件</span>
          </div>
        </router-link>
      </div>
      <div id="option2" class="optionGoods" @mouseleave="hiddenGoods2">
        <router-link to="/home/cg2">
          <div style="float: left; width: 50px; height: 50px; margin-top: 25px;">
            <div class="nav_img_one two_img" style="background-size: 100% 100%; width: 50px; height: 50px; margin-bottom: -35px;"></div>
            <span style="font-size: 12px; color: #111111;">CG-2</span>
          </div>
        </router-link>
        <router-link to="/home/parts">
          <div style="background-size: 100% 100%; float: left; width: 50px; height: 50px; margin-top: 25px; margin-left: 80px;">
            <div class="nav_img_two" style="background-size: 100% 100%; width: 40px; height: 40px; margin-bottom: -25px;"></div>
            <span style="font-size: 12px; color: #111111;">配件</span>
          </div>
        </router-link>
      </div>
      <div id="option3" class="optionGoods" @mouseleave="hiddenGoods3">
        <router-link to="/home/aio">
          <div style="float: left; width: 50px; height: 50px; margin-top: 25px;">
            <div class="nav_img_one three_img" style="background-size: 100% 100%; width: 50px; height: 50px; margin-bottom: -35px;"></div>
            <span style="font-size: 12px; color: #111111;">XR3</span>
          </div>
        </router-link>
        <router-link to="/home/parts">
          <div style="float: left; width: 50px; height: 50px; margin-top: 25px; margin-left: 80px;">
            <div class="nav_img_two" style="background-size: 100% 100%; width: 40px; height: 40px; margin-bottom: -25px;"></div>
            <span style="font-size: 12px; color: #111111;">配件</span>
          </div>
        </router-link>
      </div>
      <div id="option4" class="optionGoods" @mouseleave="hiddenGoods4">
        <router-link to="/home/g3">
          <div style="float: left; width: 50px; height: 50px; margin-top: 25px;">
            <div class="nav_img_one four_img" style="background-size: 100% 100%; width: 50px; height: 50px; margin-bottom: -35px;"></div>
            <span style="font-size: 12px; color: #111111;">G3</span>
          </div>
        </router-link>
        <router-link to="/home/parts">
          <div style="float: left; width: 50px; height: 50px; margin-top: 25px; margin-left: 80px;">
            <div class="nav_img_two" style="background-size: 100% 100%; width: 40px; height: 40px; margin-bottom: -25px;"></div>
            <span style="font-size: 12px; color: #111111;">配件</span>
          </div>
        </router-link>
      </div>
      <router-view />
    </el-main>
    <el-footer>
      <Foot></Foot>
    </el-footer>

  </el-container>

</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
import AIO from '@/components/home/AIO.vue'

export default {
  name: 'Home',
  components: {
    Head,
    Foot,
    AIO
  },
  methods: {
    showgoods() {
      let ele = document.querySelector('#option1')
      ele.style.display = 'block'

      let ele2 = document.querySelector('#option2')
      ele2.style.display = 'none'

      let ele3 = document.querySelector('#option3')
      ele3.style.display = 'none'

      let ele4 = document.querySelector('#option4')
      ele4.style.display = 'none'
    },
    hiddenGoods() {
      let ele = document.querySelector('#option1')
      ele.style.display = 'none'
    },
    showgoods2() {
      let ele = document.querySelector('#option2')
      ele.style.display = 'block'

      let ele2 = document.querySelector('#option1')
      ele2.style.display = 'none'

      let ele3 = document.querySelector('#option3')
      ele3.style.display = 'none'

      let ele4 = document.querySelector('#option4')
      ele4.style.display = 'none'
    },
    hiddenGoods2() {
      let ele = document.querySelector('#option2')
      ele.style.display = 'none'
    },
    showgoods3() {
      let ele = document.querySelector('#option3')
      ele.style.display = 'block'

      let ele2 = document.querySelector('#option1')
      ele2.style.display = 'none'

      let ele3 = document.querySelector('#option2')
      ele3.style.display = 'none'

      let ele4 = document.querySelector('#option4')
      ele4.style.display = 'none'
    },
    hiddenGoods3() {
      let ele = document.querySelector('#option3')
      ele.style.display = 'none'
    },
    showgoods4() {
      let ele = document.querySelector('#option4')
      ele.style.display = 'block'

      let ele2 = document.querySelector('#option1')
      ele2.style.display = 'none'

      let ele3 = document.querySelector('#option2')
      ele3.style.display = 'none'

      let ele4 = document.querySelector('#option3')
      ele4.style.display = 'none'
    },
    hiddenGoods4() {
      let ele = document.querySelector('#option4')
      ele.style.display = 'none'
    }
  }
}
</script>
<style scoped lang="less">
.el-main {
  // height: 2000px;
  // background-color: pink;
  padding: 0;
  .optionGoods {
    padding-left: 33%;
    display: none;
    height: 100px;
    line-height: 100px;
    background-color: #fff;
    animation: showGoods 0.3s linear;
  }
  @keyframes showGoods {
    0% {
      height: 0;
    }
    100% {
      height: 100px;
    }
  }
}
.el-header {
  padding: 0;
}
.el-footer {
  padding: 0;
}
.nav_img_one {
  background: url('../../public/images/aio/small_aio.png') no-repeat;
  background-size: 100% 100%;
}
.nav_img_two {
  background: url('../../public/images/aio/small_charge.png') no-repeat;
  background-size: 50px 25px;
  transform: translateY(10px);
}
.two_img {
  background: url('../../public/images/aio/two.png') no-repeat;
}
.three_img {
  background: url('../../public/images/aio/three.png') no-repeat;
}
.four_img {
  background: url('../../public/images/aio/four.png') no-repeat;
}
</style>
