<template>
  <el-row>
    <el-col :span="24" style="background: #F8F8F8;">
      <div class="foot_top main">
        <dl>
          <dt>产品</dt>
          <dd><a href="#/home/aio5">AIO-5智能车机</a></dd>
          <dd><a href="#/home/cg2">CG-2手机支架</a></dd>
          <dd><a href="#">XR-3行车记录仪</a></dd>
          <dd><a href="#/home/g3">G3胎压监测</a></dd>
        </dl>
        <dl>
          <dt>服务支持</dt>
          <dd><a href="#/home/ofenanswer">常见问题</a></dd>
          <dd><a href="#/home/support_video">教程视频</a></dd>
          <dd><a href="#/home/support_download/down_one">下载</a></dd>
        </dl>
        <dl>
          <dt>关于我们</dt>
          <dd><a href="#">我们的故事</a></dd>
          <dd><a href="#/home/contact">联系我们</a></dd>
        </dl>
        <dl>
          <dt>业务合作</dt>
          <dd><a href="#/home/todealer">成为经销商</a></dd>
        </dl>
      </div>
      <div class="foot_middle main">
        <div style="position: relative;">
          <img :src="wx_erwei" class="wx_erwei" alt="二维码" style="display: none; position: absolute; left: -100px; top: -240px;">
          <img :src="wx" alt="wx" class="wx" @mouseover="wxShow" @mouseleave="wxHidden" style="width: 38px; height: 31px; position: absolute; left: 0;">
          <img :src="dy_erwei" alt="二维码" class="dy_erwei" style="display: none; position: absolute; left: 60px; top: -240px;">
          <img :src="dy" alt="dy" @mouseover="dyShow" @mouseleave="dyHidden" style="width: 32px; height: 35px; position: absolute; left: 160px;">
          <div class="email"></div>
        </div>
      </div>
      <div class="foot_bottom">
        <span style="color: #5E5E5E; font-size: 20px;">版权所有©深圳市骑技科技有限公司保留所有权利。<a href="https://beian.miit.gov.cn/" tatget="_blank">粤ICP备2021085794号-1</a></span>
      </div>
    </el-col>
  </el-row>

</template>
<script>
export default {
  data() {
    return {
      wx: require('../../public/images/wx.png'),
      wx_erwei: require('../../public/images/wx_erwei.png'),
      dy_erwei: require('../../public/images/aio/dy.png'),
      dy: require('../../public/images/dy.png')
    }
  },
  methods: {
    wxShow() {
      let ele = document.querySelector('.wx_erwei')
      ele.style.display = 'block'
    },
    wxHidden() {
      let ele = document.querySelector('.wx_erwei')
      ele.style.display = 'none'
    },
    dyShow() {
      let ele = document.querySelector('.dy_erwei')
      ele.style.display = 'block'
    },
    dyHidden() {
      let ele = document.querySelector('.dy_erwei')
      ele.style.display = 'none'
    }
  }
}
</script>
<style scoped lang="less">
.main {
  text-align: left;
  padding: 0 20%;
}
.foot_top {
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  dl,
  a {
    line-height: 40px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-size: 15px;
    color: #111111;
  }
  dt {
    font-weight: 500;
  }
}
.foot_middle {
  // background-color: pink;
  height: 260px;
  padding-top: 180px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  .email {
    float: right;
    width: 300px;
    height: 200px;
    background: url('../../public//images//email.png') no-repeat;
    background-size: 100% auto;
  }
}
.foot_bottom {
  position: relative;
  height: 120px;
  // background-color: green;
  line-height: 120px;
  span {
    position: absolute;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
}
</style>